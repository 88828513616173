@import url("https://fonts.googleapis.com/css2?family=Anta&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

body {
	margin: 0;
	user-select: none;
}
* {
	font-family: "Lato", serif;
	font-style: normal;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
img {
	width: 100%;
}
a {
	text-decoration: none;
}
.font-anta {
	font-family: "Anta", sans-serif !important;
}
.content {
	margin-left: 256px;
	padding-top: 10px;
}
@media (max-width: 768px) {
	.content {
		margin-left: 0;
	}
}

.text3 {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.text3::after {
	content: "...";
	display: block;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis !important;
}
@keyframes shake {
	0% {
		transform: translate(1px, 1px) rotate(0deg);
	}
	10% {
		transform: translate(-1px, -2px) rotate(-1deg);
	}
	20% {
		transform: translate(-3px, 0px) rotate(1deg);
	}
	30% {
		transform: translate(3px, 2px) rotate(0deg);
	}
	40% {
		transform: translate(1px, -1px) rotate(1deg);
	}
	50% {
		transform: translate(-1px, 2px) rotate(-1deg);
	}
	60% {
		transform: translate(-3px, 1px) rotate(0deg);
	}
	70% {
		transform: translate(3px, 1px) rotate(-1deg);
	}
	80% {
		transform: translate(-1px, -1px) rotate(1deg);
	}
	90% {
		transform: translate(1px, 2px) rotate(0deg);
	}
	100% {
		transform: translate(1px, -2px) rotate(-1deg);
	}
}
.shake-text {
	display: inline-block;
	animation: shake 3s infinite;
}

.win-center {
	position: absolute;
	transform: translate(-50%, -50%);
}
